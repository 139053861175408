const lowest = 'lowest';
const low = 'low';
const medium = 'medium';
const high = 'high';
const highest = 'highest';
const red = 'red';
const yellow = 'yellow';
const green = 'green';
const blue = 'blue';

export const PRIORITIES = {
	lowest,
	low,
	medium,
	high,
	highest,
};

export const THEMES = {
	red,
	yellow,
	green,
	blue,
};

export const DEFAULTS = {
	regards: 'default_regards',
	signature: 'default_signature',
	theme: 'yellow',
	priority: 'medium',
};

export default {};
