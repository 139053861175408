<!-- eslint-disable vue/no-v-html -->
<template>
	<div
		class="text-right">
		<b-row
			v-if="$can('announcements', 'create')"
			class="mb-3">
			<b-col>
				<b-button
					variant="primary"
					@click="$router.push({ name: 'NewAnnouncement'});">
					{{ translate('create') }}
				</b-button>
			</b-col>
		</b-row>
		<hr>
		<div v-if="!loading && data.length > 0">
			<div
				v-for="announcementInfo in data"
				:key="announcementInfo.id">
				<div class="row">
					<div class="col-xl-11 col-sm-10 col-9 pr-0">
						<show-announcement
							:theme="announcementInfo.attributes.theme"
							:content="announcementInfo.attributes.content" />
					</div>
					<div class="col-xl-1 col-sm-2 col-3 mt-1 mb-2 text-left">
						<div class="row">
							<div class="col-12">
								<b-button
									v-if="$can('announcements', 'update')"
									v-b-tooltip.hover
									:title="translate('update_announcement')"
									class="mt-1 bg-primary-alt"
									variant="primary"
									@click="updateAnnouncement(announcementInfo.id)">
									<i
										class="fa fa-pen"
										aria-hidden="true" />
								</b-button>
							</div>
						</div>
						<div class="row">
							<div class="col-12">
								<b-button
									v-if="$can('announcements', 'delete')"
									v-b-tooltip.hover
									:title="translate('delete_announcement')"
									:style="{ width: '40px' }"
									class="mt-1 bg-primary-alt"
									variant="danger"
									@click="deleteAnnouncement(announcementInfo.id)">
									<i
										class="fa fa-trash-alt"
										aria-hidden="true" />
								</b-button>
							</div>
						</div>
					</div>
					<div class="col-xl-11 col-sm-10 col-11 pr-0">
						<div class="row">
							<div class="col-8 h5 text-left mb-1">
								<div v-if="announcementInfo.attributes.end_date">
									<i
										class="fa fa-clock"
										aria-hidden="true" /> {{ translate('from') }} <span class="font-weight-normal font-italic">{{ $moment(announcementInfo.attributes.start_date.date).format('LLL') }}</span> {{ translate('to') }} <span class="font-weight-normal font-italic">{{ $moment(announcementInfo.attributes.end_date.date).format('LLL') }}</span>
								</div>
								<div v-if="!announcementInfo.attributes.end_date">
									<i
										class="fa fa-clock"
										aria-hidden="true" /> {{ translate('starts_at') }}: <span class="font-weight-normal font-italic">{{ $moment(announcementInfo.attributes.start_date.date).format('LLL') }}</span>
								</div>
							</div>
							<div
								:class="['xs', 'sm'].includes(windowWidth) ? 'px-0' : ''"
								class="col-4 h5 text-right">
								<i
									class="fa fa-arrow-up"
									aria-hidden="true" /> <span v-html="showPriority(announcementInfo.attributes.priority)" />
							</div>
						</div>
					</div>
					<div class="col-xl-11 col-sm-10 col-11 pr-0">
						<div class="row">
							<div class="col h5 text-left mb-1">
								<i
									:class="announcementInfo.attributes.allow_to_hide ? 'fa-eye-slash' : 'fa-eye'"
									class="fa" /> <span class="font-weight-normal">{{ translate(announcementInfo.attributes.allow_to_hide ? 'can_be_hidden' : 'can_not_be_hidden') }}</span>
							</div>
						</div>
					</div>
				</div>
				<hr>
			</div>
		</div>
		<is-loading
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:loading="loading"
			:has-data="hasData" />
		<b-pagination
			v-if="pagination.total_pages > 1"
			v-model="pagination.current_page"
			:total-rows="pagination.total"
			:per-page="pagination.per_page"
			align="center"
			@change="getData" />
	</div>
</template>
<script>
import ShowAnnouncement from '@/components/Announcement';
import WindowSizes from '@/mixins/WindowSizes';
import { PRIORITIES, THEMES } from '@/settings/Announcements';
import { PAGINATION } from '@/settings/RequestReply';
import { Announcements as announcementsTranslations, Time } from '@/translations';
import Announcement from '@/util/Announcement';

export default {
	name: 'Announcements',
	messages: [announcementsTranslations, Time],
	components: {
		ShowAnnouncement,
	},
	mixins: [WindowSizes],
	data() {
		return {
			announcement: new Announcement(),
			removeAnnouncement: new Announcement(),
			priorities: PRIORITIES,
			themes: THEMES,
			alert: new this.$Alert(),
		};
	},
	computed: {
		pagination() {
			return this.announcement.data.pagination;
		},
		loading() {
			return !!this.announcement.data.loading;
		},
		errors() {
			return this.announcement.data.errors.errors;
		},
		data: {
			get() {
				try {
					const { data } = this.announcement.data.response.data;
					return data;
				} catch (error) {
					return [];
				}
			},
			set(payload) {
				this.announcement.data.response.data = { ...this.announcement.data.response.data, ...payload };
			},
		},
		hasData() {
			const response = this.data.length;
			return !!response;
		},
	},
	mounted() {
		this.announcement.getAnnouncements();
	},
	methods: {
		getData(page) {
			const { query } = this.$route;
			const options = { ...query };
			if (typeof page === 'number') {
				options[PAGINATION] = page;
			}

			this.announcement.getAnnouncements(options);
		},
		deleteAnnouncement(id) {
			const trans = {
				title: this.translate('delete_announcement'),
				text: this.translate('password'),
			};
			const options = {
				buttonText: this.translate('ok'),
				cancelButtonText: this.translate('cancel'),
				emptyPasswordMessage: this.translate('password_is_required'),
			};
			this.alert.confirmationWithPassword(trans.title, trans.text, options).then((password) => {
				const payload = {
					password: password.value,
				};
				this.removeAnnouncement.deleteAnnouncement(id, payload).then(() => {
					try {
						this.alert.toast('success', this.translate('announcement_deleted'));
					} finally {
						this.announcement.getAnnouncements();
					}
				}).catch(() => {
					let errorName = 'default_error_message';
					if (typeof this.removeAnnouncement.data.errors.errors.password !== 'undefined') {
						errorName = 'incorrect_password';
						delete this.removeAnnouncement.data.errors.errors.password;
					}
					this.alert.toast('error', this.translate(errorName));
					this.data = payload;
				});
			}).catch(() => {});
		},
		updateAnnouncement(announcementId) {
			this.$router.push({ name: 'UpdateAnnouncement', params: { announcementId } });
		},
		showPriority(priority) {
			return this.translate('priority_label', { priority: this.translate(priority) });
		},
	},
};
</script>
